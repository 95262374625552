/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import Masquerade, { NetworkData } from '@ally/masquerade'
import { once } from '@ally/utilitarian'
import LogRocket from 'logrocket'
import { logRocketAppId, logRocketIsEnabled } from '../../constants'
import log from '../../whisper'
import { toProxyObject } from '../toProxyObject'

import {
  logRocketIngestServer,
  logRocketSdkServer,
  logRocketConfig,
} from './constants'

interface LogRocketUserTraits {
  [propName: string]: string | number | boolean
}

export class LogRocketService {
  // initialize LR
  private logRocketInstance: typeof LogRocket

  private masqueradeInstance: ReturnType<typeof Masquerade>

  constructor() {
    this.logRocketInstance = require('logrocket/setup')({
      sdkServer: logRocketSdkServer,
      ingestServer: logRocketIngestServer,
    })

    this.masqueradeInstance = Masquerade()

    if (!logRocketIsEnabled) {
      log.warn({ message: '[NOTICE] LOGROCKET IS DISABLED!' })
      return
    }

    this.logRocketInstance.init(logRocketAppId, {
      ...logRocketConfig,
      network: {
        isEnabled: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        requestSanitizer: this.requestSanitizer.bind(this) as any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        responseSanitizer: this.responseSanitizer.bind(this) as any,
      },
    })
    this.logRocketInstance.getSessionURL(sessionURL => {
      window.LogRocket = { sessionURL }
    })
  }

  // Set Masquerade Config (called after we get updated LD flags)
  // TODO: Once LaunchDarkly is refactored into a smiliar service we should
  // be getting flag state when this service is constructed as an observable
  // to react to.
  setMasqueradeConfig(...args: Parameters<typeof Masquerade>): void {
    this.masqueradeInstance = Masquerade(...args)
  }

  private requestSanitizer(data: NetworkData): NetworkData | null {
    return this.masqueradeInstance.requestSanitizer(data)
  }

  private responseSanitizer(data: NetworkData): NetworkData | null {
    return this.masqueradeInstance.responseSanitizer(data)
  }

  captureException(
    ...args: Parameters<typeof LogRocket['captureException']>
  ): ReturnType<typeof LogRocket['captureException']> {
    return this.logRocketInstance.captureException(...args)
  }

  identify(uid: string, traits?: LogRocketUserTraits): void {
    return this.logRocketInstance.identify(uid, traits)
  }

  track(
    ...args: Parameters<typeof LogRocket['track']>
  ): ReturnType<typeof LogRocket['track']> {
    return this.logRocketInstance.track(...args)
  }
}

export default once(() => toProxyObject(new LogRocketService()))
